import './index.scss'

import React from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'

const bem = new BEMHelper('logo')

export default function Logo ({ className, full }) {
  if (full) {
    return (
      <figure {...bem('', 'large', className)}>
        <svg viewBox="0 0 320 50" {...bem('svg')}>
          <title>North Sea Container Line</title>

          <g>
            <g {...bem('letters')}>
              <path
                {...bem('letter')}
                d="M29.8,0.2C15.5,0.2,7.2,7.7,4.5,23L0,49.1h12.5l4.3-25.3c1.4-8.4,4.9-12.5,10.7-12.5c5.7,0,8.6,2.9,8.6,8.5 c0,1.3-0.5,4.3-0.5,4.3l-4.4,25h12.4l4.6-26.3c0,0,0.5-3.1,0.5-4.6C48.9,7.3,41.4,0.2,29.8,0.2z"
              />
              <path
                {...bem('letter')}
                d="M53.7,29.4c0,11.5,7.6,19.7,20.7,19.7h11.8l1.9-10.9H75.8c-0.4,0-1.7,0-2.1-0.1c-4.2-0.5-7.6-3.7-7.6-9.8 c0-10.6,5.9-15.9,12.4-16.5c0.4,0,0.9,0,1.3,0H91l1.9-10.9H80.7C63.7,0.8,53.7,12.5,53.7,29.4z"
              />
              <path
                {...bem('letter')}
                d="M106.8,31.5c0-1.1,0.1-2.3,0.4-3.6L112,0.8H99.9l-4.9,28c-0.3,1.4-0.4,2.7-0.4,4.1c0,9.1,6.2,16.2,17.1,16.2 h16.4l1.9-10.9h-16.4C108.7,38.1,106.8,35.6,106.8,31.5z"
              />
            </g>

            <g {...bem('company-name')}>
              <polygon points="162.7,7.2 166.7,17.9 170.9,17.9 173.9,0.4 170.1,0.4 168.2,11.3 164.2,0.4 160.1,0.4 157,17.9 160.8,17.9" />
              <path d="M180.1,18.2c4.5,0,7.4-3.3,7.3-7.7c0-3.2-2.3-5.4-5.7-5.4c-4.4,0-7.4,3.1-7.3,7.7 C174.4,16,176.7,18.2,180.1,18.2z M181.4,8.4c1.4,0,2.4,1.1,2.4,2.6c0,2.2-1.5,3.8-3.3,3.8c-1.5,0-2.4-1.1-2.4-2.7 C178.1,9.8,179.6,8.4,181.4,8.4z" />
              <path d="M193.9,6.8V5.4h-3.1l-2.2,12.5h3.6l1.1-6.2c0.3-2,1.5-2.8,2.8-2.8c0.7,0,1.1,0.2,1.5,0.4l1.4-3.7 c-0.5-0.3-1.1-0.5-1.9-0.5C195.9,5.1,194.7,5.6,193.9,6.8z" />
              <polygon points="199.6,17.9 203.2,17.9 204.9,8.3 207.3,8.3 207.8,5.4 205.4,5.4 206.1,1.3 202.4,2.1 201.8,5.4 200,5.4 199.4,8.3 201.3,8.3" />
              <path d="M211.1,17.9l1-6c0.4-2.3,1.7-3.4,2.8-3.4c1,0,1.6,0.6,1.6,1.7c0,0.3-0.1,0.7-0.1,1l-1.2,6.7h3.6l1.4-7.8 c0.1-0.4,0.1-0.8,0.1-1.2c0-2.2-1.6-3.7-3.7-3.7c-1.5,0-2.7,0.6-3.5,1.5l1.1-6.5h-3.6l-3.1,17.9H211.1z" />
              <path d="M233.7,14.6c-1.4,0-2.2-0.9-2.5-2.4l-3.5,0.8c0.4,3.1,2.3,5.1,5.6,5.1c4,0,6.6-2.5,6.5-6 c0-1.9-0.9-3.4-2.6-4.6c-0.2-0.1-1.9-1.2-2.1-1.4c-0.7-0.5-1-0.9-1-1.4c0-0.6,0.4-1.1,1.4-1.1c0.7,0,1.1,0.5,1.3,1.3l3.4-0.9 c-0.4-2.3-1.9-3.8-4.5-3.8c-3.5,0-5.6,2.1-5.6,5.1c0,1.8,1,3.3,2.3,4.1c0.2,0.2,1.6,1,1.9,1.2c1.1,0.7,1.5,1.4,1.5,2.1 C236,13.8,235.1,14.6,233.7,14.6z" />
              <path d="M253.2,15.3l-2.8-1.9c-0.6,0.7-1.4,1.4-2.8,1.4c-1.4,0-2.3-0.9-2.4-2.3h8.4c0.3-0.8,0.5-1.7,0.5-2.5 c0-2.7-2-5-5.3-5c-4.4,0-7.1,3.2-7.1,7.6c0,3.3,2.3,5.4,6,5.4C250.1,18.2,252,17,253.2,15.3z M248.4,8.1c1.2,0,2,0.8,2,2h-4.8 C246.1,8.9,247.1,8.1,248.4,8.1z" />
              <path d="M265.7,6.5c-0.6-0.7-1.8-1.4-3.3-1.4c-3.7,0-6.9,3.1-6.9,7.7c0,3.1,2.2,5.4,5,5.4c1.7,0,2.8-0.6,3.5-1.3 l0.2,1h3.1l2.2-12.5h-3.3L265.7,6.5z M261.7,14.9c-1.5,0-2.5-1-2.5-2.8c0-2,1.3-3.6,3.4-3.6c1.4,0,2.5,1.1,2.5,2.8 C265.1,13.1,263.9,14.9,261.7,14.9z" />
              <path d="M161.9,45.7c-2.1,0-3.8-1.7-3.8-4.3c0-4.3,2.7-6.7,5.6-6.7c1.7,0,2.8,1,3.2,2.6l3.6-1.1c-0.7-3.2-3.3-5-6.5-5 c-5.9,0-9.9,4.3-9.9,10.7c0,4.2,2.9,7.4,7.5,7.4c3.9,0,6.2-1.8,7.8-4.2l-3.2-1.8C165.3,44.8,164,45.7,161.9,45.7z" />
              <path d="M177.8,36.2c-4.4,0-7.4,3.1-7.3,7.7c0,3.2,2.3,5.4,5.7,5.4c4.5,0,7.4-3.3,7.3-7.7 C183.5,38.4,181.2,36.2,177.8,36.2z M176.6,46c-1.5,0-2.4-1.1-2.4-2.7c0-2.3,1.4-3.8,3.3-3.8c1.4,0,2.4,1.1,2.4,2.6 C179.9,44.4,178.4,46,176.6,46z" />
              <path d="M194.2,36.2c-1.6,0-3.2,1-3.9,1.9v-1.6h-3.1L185,49h3.6l1.1-6c0.4-2.3,1.6-3.4,2.8-3.4c1,0,1.5,0.6,1.5,1.6 c0,0.3-0.1,0.8-0.1,1.1l-1.2,6.7h3.6l1.4-7.8c0.1-0.4,0.1-0.8,0.1-1.2C197.8,37.6,196.3,36.2,194.2,36.2z" />
              <polygon points="205.5,36.5 206.2,32.5 202.4,33.2 201.9,36.5 200,36.5 199.5,39.5 201.4,39.5 199.7,49 203.3,49 204.9,39.5 207.4,39.5 207.9,36.5" />
              <path d="M217.8,37.6c-0.6-0.7-1.8-1.4-3.3-1.4c-3.7,0-6.9,3.1-6.9,7.7c0,3.1,2.2,5.4,5,5.4c1.7,0,2.8-0.6,3.5-1.3 l0.2,1h3.1l2.2-12.5h-3.3L217.8,37.6z M213.8,46c-1.5,0-2.5-1-2.5-2.8c0-2,1.3-3.6,3.4-3.6c1.4,0,2.5,1.1,2.5,2.8 C217.2,44.2,216,46,213.8,46z" />
              <polygon points="222.5,49 226.1,49 228.3,36.5 224.7,36.5" />
              <polygon points="224.9,34.9 228.6,34.9 229.3,31.3 225.6,31.3" />
              <path d="M238.6,36.2c-1.6,0-3.2,1-3.9,1.9v-1.6h-3.1L229.3,49h3.6l1.1-6c0.4-2.3,1.6-3.4,2.8-3.4c1,0,1.5,0.6,1.5,1.6 c0,0.3-0.1,0.8-0.1,1.1L237,49h3.6l1.4-7.8c0.1-0.4,0.1-0.8,0.1-1.2C242.1,37.6,240.6,36.2,238.6,36.2z" />
              <path d="M250.8,36.2c-4.4,0-7.1,3.2-7.1,7.6c0,3.3,2.3,5.4,6,5.4c2.6,0,4.4-1.2,5.6-2.8l-2.8-1.9 c-0.6,0.7-1.4,1.4-2.8,1.4c-1.4,0-2.3-0.9-2.4-2.3h8.4c0.3-0.8,0.5-1.7,0.5-2.5C256.2,38.5,254.1,36.2,250.8,36.2z M247.8,41.2 c0.4-1.1,1.4-2,2.8-2c1.2,0,2,0.8,2,2H247.8z" />
              <path d="M262.4,37.9v-1.4h-3.1L257.1,49h3.6l1.1-6.2c0.3-2,1.5-2.8,2.8-2.8c0.7,0,1.1,0.2,1.5,0.4l1.4-3.7 c-0.5-0.3-1.1-0.5-1.9-0.5C264.3,36.2,263.2,36.8,262.4,37.9z" />
              <polygon points="280.7,31.5 276.8,31.5 273.8,49 284.4,49 285.1,45.5 278.3,45.5" />
              <polygon points="286.3,49 289.9,49 292.1,36.5 288.5,36.5" />
              <polygon points="288.7,34.9 292.4,34.9 293.1,31.3 289.4,31.3" />
              <path d="M302.4,36.2c-1.6,0-3.2,1-3.9,1.9v-1.6h-3.1L293.2,49h3.6l1.1-6c0.4-2.3,1.6-3.4,2.8-3.4c1,0,1.5,0.6,1.5,1.6 c0,0.3-0.1,0.8-0.1,1.1l-1.2,6.7h3.6l1.4-7.8c0.1-0.4,0.1-0.8,0.1-1.2C306,37.6,304.5,36.2,302.4,36.2z" />
              <path d="M320,41.2c0-2.7-2-5-5.3-5c-4.4,0-7.1,3.2-7.1,7.6c0,3.3,2.3,5.4,6,5.4c2.6,0,4.4-1.2,5.6-2.8l-2.8-1.9 c-0.6,0.7-1.4,1.4-2.8,1.4c-1.4,0-2.3-0.9-2.4-2.3h8.4C319.8,43,320,42,320,41.2z M311.6,41.2c0.4-1.1,1.4-2,2.8-2 c1.2,0,2,0.8,2,2H311.6z" />
            </g>
          </g>
        </svg>
      </figure>
    )
  }

  return (
    <figure {...bem('', '', className)}>
      <svg viewBox="0 0 66 24" {...bem('svg')}>
        <title>North Sea Container Line</title>
        <path
          {...bem('letter')}
          d="M14.7,0C7.6,0,3.6,3.7,2.2,11.2L0,24h6.2l2.1-12.4c0.7-4.1,2.4-6.2,5.3-6.2c2.8,0,4.2,1.4,4.2,4.2 c0,0.6-0.2,2.1-0.2,2.1L15.4,24h6.1l2.2-12.9c0,0,0.2-1.5,0.2-2.2C24,3.5,20.3,0,14.7,0z"
        />
        <path
          {...bem('letter')}
          d="M26.4,14.3c0,5.6,3.7,9.7,10.2,9.7h5.8l0.9-5.4h-6c-0.2,0-0.8,0-1.1,0c-2-0.3-3.8-1.8-3.8-4.8 c0-5.2,2.9-7.8,6.1-8.1c0.2,0,0.4,0,0.7,0h5.5l0.9-5.4h-6C31.3,0.3,26.4,6.1,26.4,14.3z"
        />
        <path
          {...bem('letter')}
          d="M52.5,15.4c0-0.5,0.1-1.1,0.2-1.8l2.4-13.3h-6L46.7,14c-0.1,0.7-0.2,1.3-0.2,2c0,4.5,3,8,8.4,8H63l0.9-5.4 h-8.1C53.4,18.6,52.5,17.4,52.5,15.4z"
        />
      </svg>
    </figure>
  )
}

Logo.propTypes = {
  className: PropTypes.string,
  full: PropTypes.bool,
}
