import '../styles/index.scss'
import './page-transitions.scss'

import React from 'react'
import PropTypes from 'prop-types'
import Transition from '../components/Transition'
import SEO from '../components/SEO'
import Header from '../components/Header'
import Footer from '../components/Footer'

const config = require('../../config.json')

function isTopTask({ pathname }) {
  return (
    pathname === '/' ||
    (pathname.indexOf('/prices') > -1 &&
      pathname.indexOf('/prices/thank-you') === -1) ||
    pathname.indexOf('/tracking') > -1
  )
}

function isBrandbook({ pathname }) {
  return pathname.indexOf('/brandbook') > -1
}

function shouldBeLight({ pathname }) {
  return pathname.indexOf('/track') > -1
}

export default function Layout({ children, location, ...props }) {
  const { pathname } = location

  const {
    props: { data },
  } = children

  const heroImage = data && data.ievv && data.ievv.heroImage

  const topTaskPage = isTopTask(location)
  const darkHeader =
    !heroImage && !isBrandbook(location) && !shouldBeLight(location)
  const brandbook = isBrandbook(location)

  const topTasks = brandbook
    ? [{ name: 'Brand book', link: '/brandbook/' }]
    : [
        { name: 'Schedule', link: '/' },
        { name: 'Get price', link: '/prices/' },
        { name: 'Track', link: '/tracking/' },
      ]

  const secondaryTasks = brandbook
    ? [
        { name: 'Logotype', link: '/brandbook/logo/' },
        { name: 'Colors', link: '/brandbook/colors/' },
        { name: 'Typography', link: '/brandbook/typography/' },
        { name: 'Icons', link: '/brandbook/icons/' },
        {
          name: 'Interactive elements',
          link: '/brandbook/interactive-elements/',
        },
        { name: 'For the office', link: '/brandbook/for-the-office/' },
      ]
    : [
        { name: 'Services', link: '/services/' },
        { name: 'Sea Change', link: '/campaigns/sea-change/' },
        { name: 'Ports and routes', link: '/ports-and-routes/' },
        { name: 'Scheduling table', link: '/ports-and-routes/schedule/' },
        {
          name: 'Customer Portal: eSea',
          link: config.customer_portal_url,
          external: true,
        },
        { name: 'Track and trace', link: '/tracking/' },
        { name: 'Vessels', link: '/vessels/' },
        { name: 'About NCL', link: '/about/ncl/' },
        { name: 'Contact us', link: '/about/contact/' },
      ]

  return (
    <main className={`main main--${pathname}`}>
      <SEO pathname={pathname} />

      <Header
        promo={topTaskPage}
        dark={darkHeader}
        location={location}
        topTasks={topTasks}
        secondaryTasks={secondaryTasks}
        brandbook={brandbook}
      />

      <Transition updateKey={pathname} className="page">
        {children}
      </Transition>

      {!brandbook && <Footer />}
    </main>
  )
}

Layout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.any.isRequired,
}
