import './index.scss'

import React from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'

const bem = new BEMHelper('container')

export default function Container ({
  children,
  small,
  medium,
  large,
  fluid,
  space,
  className,
  style,
  nodeType,
  ...props
}) {
  const Node = nodeType

  return (
    <Node
      {...bem('', { small, medium, large, fluid, space }, className)}
      style={style}
      {...props}
    >
      {children}
    </Node>
  )
}

Container.propTypes = {
  children: PropTypes.any,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  fluid: PropTypes.bool,
  space: PropTypes.bool,
  className: PropTypes.string,
  nodeType: PropTypes.string,
  style: PropTypes.object,
}

Container.defaultProps = {
  nodeType: 'section',
  style: {},
}
