export function phoneFormat (phone, type = 'mobile') {
  if (!phone) {
    return null
  }

  if (type === 'office') {
    return `${phone
      .split(' ')
      .join('')
      .replace(/(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)/, '$1 $2 $3 $4 $5')}`
  }
  // return `(${phone.replace(/(\d\d)(\d\d\d)(\d\d)(\d\d\d)/, '$1) $2 $3 $4')}`
  return `${phone
    .split(' ')
    .join('')
    .replace(/(\d\d)(\d\d\d)(\d\d)(\d\d\d)/, '$1 $2 $3 $4')}`
}

export function priceFormat (price, currency = 'EUR') {
  const prittyPrice = `${price}`
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, '\u00A0')

  return `${prittyPrice}${currency ? `\u00A0${currency}` : ''}`
}

/*
format(new Date(), 'DD MMM, YYYY HH:mm:ss') // returns local time
format(getUTCDate(), 'DD MMM, YYYY HH:mm:ss') // returns UTC time
*/

export function getUTCDate (dateString = Date.now()) {
  const date = new Date(dateString)

  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  )
}

export function stripHTML (html) {
  return html.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, '')
}

/*
function stripHTML (html) {
   const doc = new DOMParser().parseFromString(html, 'text/html');
   return doc.body.textContent || "";
}
*/
