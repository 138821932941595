import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
  TransitionGroup,
  Transition as ReactTransition,
} from 'react-transition-group'
import BEMHelper from 'react-bem-helper'

export default class Transition extends PureComponent {
  static propTypes = {
    children: PropTypes.any.isRequired,
    updateKey: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    enter: PropTypes.number,
    exit: PropTypes.number,
    nodeType: PropTypes.string,
  }

  static defaultProps = {
    enter: 1500,
    exit: 600,
    nodeType: 'div',
  }

  render () {
    const { children, updateKey, className, enter, exit, nodeType } = this.props
    const bem = new BEMHelper(className)
    const Node = nodeType

    return (
      <TransitionGroup component={null}>
        <ReactTransition key={updateKey} timeout={{ enter, exit }}>
          {status => <Node {...bem('', status)}>{children}</Node>}
        </ReactTransition>
      </TransitionGroup>
    )
  }
}
