import { graphql } from 'gatsby'

export default function imageHelper({ images = { edges: [] }, ievv }) {
  const allImages = images.edges.map(
    ({ node: { childImageSharp, name, relativePath } }) => ({
      name,
      relativePath,
      ...childImageSharp,
    }),
  )

  return function getImage(id) {
    const {
      image: { name },
    } = ievv.images.find(image => image.id === `${id}`)

    return allImages.find(image => image.name === name) || {}
  }
}

export const query = graphql`
  fragment GetImages on Query {
    images: allFile(filter: { relativePath: { regex: "/.*.(?:jpg|png)/" } }) {
      edges {
        node {
          name
          childImageSharp {
            original {
              src
            }
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
