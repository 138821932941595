/* eslint-disable complexity */
import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Twitter from './Twitter'
import Facebook from './Facebook'

import imageHelper from '../../helpers/image-helper'
import someFallback from '../../assets/images/some.png'

const MANUAL_PATHS = {
  prices: {
    title: 'Price',
    description: 'Calculate your price',
  },
}

const SEO = ({ pathname = null, article = false }) => (
  <StaticQuery
    query={graphql`
      query SEO {
        site {
          siteMetadata {
            defaultTitle: title
            defaultDescription: description
            siteUrl: url
            facebookAppID
          }
        }
        ...GetImages
        pages: allIevv {
          edges {
            node {
              title
              preamble
              path
              heroImage
              images {
                id
                image {
                  name
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const {
        site: {
          siteMetadata: {
            defaultTitle,
            defaultDescription,
            siteUrl,
            facebookAppID,
          },
        },
      } = data

      const page =
        data.pages.edges
          .map(({ node }) => ({
            path: node.path,
            title: node.title,
            description: node.preamble,
            heroImage: node.heroImage,
            images: node.images,
          }))
          .find(({ path }) => path === pathname) || {}

      const { title, description, heroImage } = page
      const getImage = imageHelper({ images: data.images, ievv: page })
      const hero = heroImage ? getImage(heroImage) : {}
      const image = hero && hero.original ? hero.original.src : null

      const seo = {
        title,
        description: description || defaultDescription,
        image: image || someFallback,
        url: `${siteUrl}${pathname || '/'}`,
        ...(MANUAL_PATHS[pathname.split('/').join('')] || {}),
      }

      return (
        <>
          <Helmet
            defaultTitle={defaultTitle}
            titleTemplate="%s | NCL"
            htmlAttributes={{ lang: 'en' }}
          >
            {seo.title && seo.title !== defaultTitle && (
              <title>{seo.title}</title>
            )}
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1, maximum-scale=1"
            />
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
          </Helmet>
          <Facebook
            pageUrl={seo.url}
            type={article ? 'article' : null}
            title={seo.title}
            description={seo.description}
            image={seo.image}
            appID={facebookAppID}
          />
          <Twitter
            title={seo.title}
            description={seo.description}
            image={seo.image}
          />
        </>
      )
    }}
  />
)

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
}

export default SEO
