import './index.scss'

import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import BEMHelper from 'react-bem-helper'
// import Img from 'gatsby-image'
import Container from '../Container'
import Logo from '../Logo'
import { phoneFormat } from '../../helpers/format'

const bem = new BEMHelper('footer')

export default function Footer() {
  const Section = ({ title, links, description }) => (
    <section {...bem('section')}>
      {title && (
        <h3 {...bem('title')}>
          {title.to ? (
            <Link to={title.to} {...bem('link')}>
              {title.name}
            </Link>
          ) : (
            title.name
          )}
        </h3>
      )}
      <ul>
        {links.map(({ name, to, href, type, ...props }, index) => (
          <li key={index} {...bem('item')}>
            {to && (
              <Link to={to} {...bem('link', type)}>
                {name}
              </Link>
            )}

            {href && (
              <a href={href} {...bem('link', type)} {...props}>
                {name}
              </a>
            )}

            {!href && !to && name}
          </li>
        ))}
      </ul>
      {description && <p {...bem('description')}>{description}</p>}
    </section>
  )

  return (
    <StaticQuery
      query={graphql`
        query {
          contact: ievv(
            pagetypeid: { eq: "ncl_backend_pageframework.GlobalContactInfo" }
          ) {
            contactData {
              phone
              phone_hotline
              emails
              address {
                location
                url
                postbox
              }
            }
          }
        }
      `}
      render={({ contact }) => {
        const contactData =
          contact && contact.contactData
            ? contact.contactData
            : {
                phone: null,
                phone_hotline: null,
                emails: [null, null],
              }

        return (
          <footer {...bem('')}>
            {/* This needs to be on top because of semantics (H2) */}
            <div {...bem('bar')}>
              <h2 {...bem('branding')}>
                <Logo full {...bem('logo')} />
              </h2>

              <p {...bem('disclaimer')}>
                <Link {...bem('link', 'inline')} to="/legal/privacy">
                  Privacy policy and cookies
                </Link>
                <Link
                  {...bem('link', 'inline')}
                  to="/legal/terms-and-conditions"
                >
                  Our terms and conditions
                </Link>
              </p>
            </div>

            <Container {...bem('content')} large>
              <Section
                title={{ name: 'Home', to: '/' }}
                links={[
                  { name: 'Schedule', to: '/' },
                  { name: 'Price', to: '/prices/' },
                ]}
              />

              <Section
                title={{ name: 'Services', to: '/services/' }}
                links={[
                  {
                    name: 'Door to door',
                    to: '/services/door-to-door/',
                  },
                  {
                    name: 'Port to port',
                    to: '/services/port-to-port/',
                  },
                  {
                    name: 'Containers and equipment',
                    to: '/services/equipment/',
                  },
                ]}
              />

              <Section
                title={{
                  name: 'Ports, routes and fleet',
                  to: '/ports-and-routes/',
                }}
                links={[
                  {
                    name: 'Scheduling table',
                    to: '/ports-and-routes/schedule/',
                  },
                  { name: 'Ports', to: '/ports-and-routes/ports/' },
                  { name: 'Our fleet', to: '/vessels/' },
                  { name: 'Track and trace', to: '/tracking/' },
                ]}
              />

              <Section
                title={{ name: 'About NCL', to: '/about/' }}
                links={[
                  { name: 'Contact us', to: '/about/contact/' },
                  { name: 'HSEQA', to: '/about/hseqa/' },
                  { name: 'Careers', to: '/about/careers/' },
                  { name: 'Åpenhetsloven', to: '/about/apenhetsloven/' },
                ]}
              />

              {/* Contact stuff */}
              <Section
                title={{ name: 'Call us' }}
                links={[
                  {
                    name: phoneFormat(contactData.phone, 'office'),
                    href: `tel:${contactData.phone}`,
                    type: 'large',
                    'aria-label': `Phone number: ${contactData.phone}`,
                  },
                ]}
                description={
                  contact.contactData.phone_hotline ? (
                    <>
                      Call our duty phone on{' '}
                      <a
                        href={`tel:${contact.contactData.phone_hotline}`}
                        {...bem('link')}
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        {phoneFormat(contact.contactData.phone_hotline)}
                      </a>{' '}
                      for inquiries outside office hours.
                    </>
                  ) : (
                    ''
                  )
                }
              />
              <Section
                title={{ name: 'Booking' }}
                links={[
                  {
                    name: contactData.emails[1],
                    href: `mailto:${contactData.emails[1]}`,
                    type: 'large',
                  },
                ]}
              />
              <Section
                title={{ name: 'Operations' }}
                links={[
                  {
                    name: contactData.emails[2],
                    href: `mailto:${contactData.emails[2]}`,
                    type: 'large',
                  },
                ]}
                description="For questions regarding shipments on route."
              />
            </Container>
          </footer>
        )
      }}
    />
  )
}
