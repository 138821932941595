import './index.scss'

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import BEMHelper from 'react-bem-helper'
import Container from '../Container'
import Logo from '../Logo'
import ReactGA from 'react-ga'

const bem = new BEMHelper('header')

const KEY_ESC = 27

export default class Header extends Component {
  static propTypes = {
    promo: PropTypes.bool,
    dark: PropTypes.bool,
    brandbook: PropTypes.bool,
    topTasks: PropTypes.array,
    secondaryTasks: PropTypes.array,
    location: PropTypes.object.isRequired,
  }

  state = {
    expanded: false,
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown = ({ keyCode }) => {
    if (keyCode === KEY_ESC) {
      this.toggleMenu(false)
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      setTimeout(() => {
        this.toggleMenu(false)
      }, 10)
    }
    return true
  }

  toggleMenu = force => {
    const expanded =
      force === false || force === true ? force : !this.state.expanded
    this.setState({ expanded })

    if (expanded) {
      document.body.classList.add('block-scrolling')
      // Log menu opening in Google Analytics
      ReactGA.event({
        category: 'Main menu',
        action: 'Open main menu',
      })
    } else {
      document.body.classList.remove('block-scrolling')
    }
  }

  renderMenuIcon = expanded => {
    const firstLine = expanded ? 'M3,3 24,24' : 'M1,5 27,5'
    const lastLine = expanded ? 'M3,24 24,3' : 'M1,23 27,23'

    return (
      <svg {...bem('icon', { expanded })} viewBox="0 0 28 28">
        <path {...bem('icon-line')} d={firstLine} />
        <path {...bem('icon-line', { hide: expanded })} d="M1,14 27,14" />
        <path {...bem('icon-line')} d={lastLine} />
      </svg>
    )
  }

  render() {
    const { expanded } = this.state
    const {
      promo,
      dark,
      topTasks,
      secondaryTasks,
      brandbook,
      location,
    } = this.props
    const isFrontPage = location.pathname === '/'

    return (
      <header {...bem('', { promo, expanded, dark, brandbook })}>
        <Container fluid {...bem('content')}>
          {isFrontPage ? (
            <Link to="/" {...bem('branding')} key="branding">
              <h1 {...bem('branding-text')}>
                <Logo />
              </h1>
            </Link>
          ) : (
            <Link to="/" {...bem('branding')} key="branding">
              <Logo />
            </Link>
          )}

          {topTasks && (
            <nav {...bem('tabs')}>
              {topTasks.map(({ name, link }) => (
                <Link
                  key={`${name}-${link}`}
                  to={link}
                  {...bem('tab')}
                  activeClassName={bem('tab', 'active').className}
                >
                  {name}
                </Link>
              ))}
            </nav>
          )}

          <button
            type="button"
            {...bem('toggle')}
            onClick={this.toggleMenu}
            aria-label="Menu"
          >
            {this.renderMenuIcon(expanded)}
          </button>
        </Container>

        {expanded && (
          <nav {...bem('menu')}>
            {secondaryTasks.map(({ name, link, external = false }) => {
              if (external) {
                return (
                  <a
                    key={`${name}-${link}`}
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={bem('link', 'active').className}
                  >
                    {name}
                  </a>
                )
              }
              return (
                <Link
                  key={`${name}-${link}`}
                  to={link}
                  {...bem('link')}
                  activeClassName={bem('link', 'active').className}
                >
                  {name}
                </Link>
              )
            })}
          </nav>
        )}
      </header>
    )
  }
}
