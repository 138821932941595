import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'

const Twitter = ({
  cardType = 'summary_large_image',
  username = null,
  title = null,
  description = null,
  image = null,
}) => (
  <Helmet>
    <meta name="twitter:card" content={cardType} />
    {username && username !== '' && (
      <meta name="twitter:creator" content={username} />
    )}
    {title && <meta name="twitter:title" content={title} />}
    {description && <meta name="twitter:description" content={description} />}
    {image && <meta name="twitter:image" content={image} />}
  </Helmet>
)

Twitter.propTypes = {
  cardType: PropTypes.string,
  username: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
}

export default Twitter
